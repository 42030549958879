$bg-header: black;
$bg-header-height: 80px;
$white: #ffffff;

// Quiz
.quiz {
  padding-top: $bg-header-height;
  width: 100%;
  background: $white;

  &--black {
    background: $bg-header;
  }
}

.quiz-wrapper {
  position: relative;
  border-top: 1px solid #363636;
}

.container-quiz {
  header.gradient-fade {
    min-height: auto !important;
    background: transparent !important;

    .gradient-fade {
      min-height: $bg-header-height;
      background: $bg-header !important;
    }

    .title-course {
      padding-top: 1rem;
    }
  }

  .video-header {
    z-index: 0;
    height: auto;
    background: $bg-header !important;
  }

  .fade-out.video-header {
    opacity: 1 !important;
  }

  .ps-link-white {
    color: $white;
    font-size: 20px;
  }
}

.ps-link-white {
  color: $white !important;
  font-weight: 700;
}

.learningCheckQuestionContainer {
  padding-top: 40px;

  @media (min-width: 1200px) {
    display: block;
    overflow-x: hidden;
    position: relative;
    height: 100%;
    min-height: 100%;
    margin: 0 auto;
  }
}

.learningCheckQuestionWrapper {
  width: 100%;
  border-radius: 3px;
  overflow-x: hidden;
  box-shadow: 0px 4px 4px 0px #dcebf7;
}

.itemBodyContainer {
  padding: 0 15px;

  @media (min-width: 1200px) {
    overflow-y: scroll;
    height: 100vh;
  }
}

.itemHeaderContent {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 50px;
  padding: 15px;
  background: $bg-header;

  @media (min-width: 1200px) {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }
}

.progressIndicatorContainer {
  width: 100%;
  height: 3px;

  .progress {
    height: 3px;
  }
}

.itemHeaderTitle,
.itemHeaderProgress {
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  margin: 0;
  color: #aaaaaa;
}

.iitemHeaderAssessmentTitle {
  font-weight: 500;
}

.itemHeaderProgress {
  margin-right: 10px;
}

.progressIndicatorContainer {
  width: 100%;
  height: 3px;
}

.progressIndicatorBar {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
}

.progressIndicatorBarCompleteness {
  background-color: #ababab;
  height: 100%;
}

.stemTextWrapper {
  width: 100%;
  padding-top: 15px;
  margin-bottom: 15px;
}

.textStem {
  margin: 0;
  outline: none;
  width: 100%;

  padding-top: 20px;
  font-size: 17px;
  line-height: 27px;
}

.choiceList {
  &.unansweredChoices,
  &.answeredChoices {
    width: 100%;
    padding-left: 0;
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
  }

  &.unansweredChoices {
    border-radius: 3px;

    @media (min-width: 1200px) {
      padding-bottom: 110px;
    }

    ul {
      padding: 0 20px !important;
    }
  }

  .textChoice {
    width: 100%;
    margin-bottom: 10px;
    -o-text-overflow: wrap;
    text-overflow: wrap;
    border: 1px solid rgba(215, 215, 214, 0.7);
    border-radius: 3px;

    cursor: pointer;
    -webkit-transition: border-color 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out;

    font-size: 16px;
    line-height: 26px;
    margin: 0;
    margin-bottom: 15px;

    button,
    div {
      display: inline-block;
      width: 99%;
      padding: 10px;
      vertical-align: middle;
    }

    & + .textChoice {
      margin-bottom: 15px;
    }
  }

  .textChoiceKeyAnswered,
  .textChoiceKey,
  .textChoiceDistractorAnswered {
    div {
      background-position: 100% 50%;
      background-repeat: no-repeat;
      background-size: 11px 9px;
    }
  }

  .textChoiceKeyAnswered {
    color: $white;
    background-color: #86ce21;

    div {
      background-image: url(/assets/icons/correct.png);
    }
  }

  .textChoiceKey {
    border: 1px solid #86ce21;

    div {
      background-image: url(/assets/icons/correct-green.png);
    }
  }

  .textChoiceDistractorAnswered {
    background-color: #ea3939;

    div {
      color: $white;
      background-image: url(/assets/icons/wrong.png);
    }
  }
}

button:focus {
  outline: none !important;
  cursor: pointer;
}

// Result Block

.result-block {
  &.container {
    @media (min-width: 1200px) {
      max-width: 830px;
    }
  }

  border: 1px solid #dfdfdf;
  background: $white;

  @media (min-width: 1200px) {
    border-radius: 11px;
    margin-top: 2rem;
  }

  .result-block-left {
    @media (max-width: 767px) {
      order: 2;
    }
  }

  .result-block-right {
    @media (max-width: 767px) {
      order: 1;
    }
  }

  .order {
    @media (max-width: 767px) {
      order: 3;
    }
  }

  .totally {
    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }

  .result-title {
    background-color: #f6f6f6;
    color: #777777;
    border: 1px solid #dfdfdf;
    border-radius: 99px;
    padding: 8px 20px;
    text-align: center;
    margin: 25px auto 6px auto;
    max-width: 150px;
    text-transform: uppercase;
    font-weight: 700;

    font-size: 12px;

    @media (min-width: 1200px) {
      font-size: 14px;
      margin: 18px auto 12px auto;
    }
  }

  .result-block-left {
    .answers {
      .ans {
        width: 90%;
        padding: 10px 20px;
        margin: 10px;
        border-radius: 85px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (min-width: 1200px) {
          padding: 10px 40px;
          margin: 10px 0px 0px 60px;
        }

        &--correct {
          color: rgb(126, 211, 33);
          border: 1px solid rgb(126, 211, 33);
        }

        &--incorrect {
          color: rgb(255, 73, 111);
          border: 1px solid rgb(255, 73, 111);
        }
      }
    }
  }

  strong {
    font-weight: 700;
  }
}

.result-block-right {
  img {
    width: 50%;
    height: 60%;

    @media (min-width: 1200px) {
      width: 70%;
      height: 70%;
    }
  }

  .totally {
  }
}

.result-block-footer {
  background: #e8ebec;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1200px) {
    flex-direction: row;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
  }

  &__left {
    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      order: 2;
    }
  }

  .btn-sm {
    padding: 0.375rem 0.75rem;
    // border-radius: 85px;

    @media (min-width: 1200px) {
      // border-radius: 99px
    }
  }
}
