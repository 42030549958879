.block-content {
    padding: 5px 15px 45px 15px;
    text-align: center;

    @media (min-width: 768px) {
        padding: 25px 15px 45px 15px;
        text-align: left;
    }
}

.block-heading {
    font-family: 'Muli-Bold', sans-serif;
    font-weight: 800;
    font-size: 2rem;
    line-height: 3rem;
    color: #1b2439;

    @media (min-width: 768px) {
        font-size: 2.7rem;
        line-height: 3.7rem;
    }
}

.block-sub-heading {
    font-family: 'Muli-Bold', sans-serif;
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #1b2439;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 1.9rem;
        line-height: 2.9rem;
        text-align: left;
    }
}

.block-desc {
    font-size: 1rem;
    line-height: 2rem;
    padding: 10px 0 20px 0;
    color: #303030;

    @media (min-width: 768px) {
        padding: 10px 0 40px 0;
    }
}

.block-btn {
    box-shadow: 0px 3px 6px #d6d6d6;
    border-radius: 0px 25px 25px 25px;
    padding: 8px 30px;
    color: #303030;
    background: white;
    border: 1px solid white;

    @media (min-width: 768px) {
        border-radius: 0px 35px 35px 35px;
        padding: 10px 30px;
    }
}

.block-btn--filled {
    background-image: -webkit-linear-gradient(90deg, #3a2dec, #17b7df);
    color: white;
    border: 1px solid #18b2e0;

    @media (max-width: 767px) {
        width: 133px;
    }
}

.dot {
    position: relative;

    &::after {
        position: absolute;
        bottom: 12px;
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        right: -20px;
        background-image: -webkit-linear-gradient(180deg, #3a2dec, #17b7df);
    }
}

.block-title {
    font-size: 1rem;
    line-height: 2rem;
    color: #303030;
}

.block-sub-panel {
    flex-direction: column;
    align-items: flex-start;
    display: flex;

    @media (min-width: 768px) {
        flex-direction: row;
    }
}

.block-content {
    .block-img {
        width: 100%;

        @media (min-width: 768px) {
            width: 90%;
        }
    }
}

.brand-logo-img {
    width: 123px;
}

.footer-block {
    padding: 30px 0;

    @media (min-width: 768px) {
        padding: 30px 15px;
    }
}

.footer-sub-block {
    @media (max-width: 767px) {
        text-align: center;
        margin: 12px auto;
    }
}

.footer-block,
.footer-sub-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
    }

    .nav-item {
        margin: 0 1rem;
    }

    .brand-logo-img {
        @media (max-width: 767px) {
            text-align: center;
            margin: 12px auto;
        }
    }
}

.bg {
    position: relative;

    &--1 {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background: url('/assets/home/patchleft.svg') no-repeat;
            width: 212px;
            height: 366px;
            transform: translateX(-100%);
        }
    }

    &--2 {
        @media (max-width: 767px) {
            flex-direction: column-reverse;
        }

        &::before {
            @media (min-width: 1200px) {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                background: url('/assets/home/patchright.svg') no-repeat;
                width: 212px;
                height: 612px;
                transform: translate(80%, -30%);
            }
        }
    }

    &--3 {
        &::before {
            @media (min-width: 1200px) {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                background: url('/assets/home/patchbottom.svg') no-repeat;
                width: 126px;
                height: 312px;
                transform: translateX(-22%);
            }
        }
    }

    &--4 {
        &::before {
            @media (min-width: 1200px) {
                content: '';
                position: absolute;
                left: 270px;
                right: 0;
                top: 0;
                bottom: 0;
                background: url(/assets/home/bgb.png) no-repeat;
                width: 1667px;
                height: 380px;
                border-radius: 35px;
            }
        }
    }
}
