@import 'styles/variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~video-react/styles/scss/video-react';
@import '~draft-js/dist/Draft.css';
@import '~draft-js-hashtag-plugin/lib/plugin.css';
@import '~rc-slider/assets/index.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'styles/markup.scss';
// @import "styles/prism.css";

// @import "~prismjs/themes/prism.css";
@import '~highlight.js/styles/monokai-sublime.css';
@import '~@fullcalendar/core/main.min.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';
@import '~@fullcalendar/bootstrap/main.css';

@import 'styles/home.scss';
@import 'styles/calendar.scss';
@import 'styles/admin.scss';

/** COMMON */
body {
    font-family: 'Muli', sans-serif;
    margin: 0;
    padding: 0;
}

.a-hover:hover {
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.navbar-expand-md .navbar-nav {
    @media (min-width: 768px) {
        flex-direction: row;
        display: flex;
        align-items: center !important;
    }

    @media (max-width: 767px) {
        width: 100%;
        align-items: flex-start;
        padding: 1rem 1rem;
    }
}

.dropdown-item.active,
.dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #eeeeee;
}

.dropdown-item {
    padding: 0.6rem 1.5rem;
}

/* Header */
.nav-eco {
    // box-shadow: $box-shadow;
    background: $white;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 99;

    .logo {
        // width: 59px;
        height: 38px;
    }

    .logo-text {
        color: $primary;
    }

    .navbar-toggler {
        border-color: transparent;

        &:focus {
            outline: none !important;
        }
    }

    .navbar-brand {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        // text-transform: uppercase;
    }

    .nav-item {
        margin: 0 0.5rem;
    }

    .dropdown.nav-item {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 19px;
            right: -6px;
            width: 0px;
            height: 0px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 6px solid #cccaca;
            margin: 0 auto;
            transition: 0.5s all ease;

            @media (max-width: 767px) {
                right: -23px;
            }
        }

        &.show {
            &:after {
                transform: rotate(180deg);
                transition: 0.5s all ease;
            }
        }
    }
    .dropdown-menu {
        @media (max-width: 767px) {
            border: none;
        }
    }
    .dropdown-menu.dropdown-menu-right.show {
        border: none;
        @media (min-width: 768px) {
            background: #ffffff;
            box-shadow: 0px 9px 8px 0px rgba(58, 54, 54, 0.1);
            border: 1px solid #fbf9f9;
            min-width: 163px;
            padding: 0;
            margin: 0.1rem -14px 0;
            font-size: 1rem;
            color: #212529;
            border-top: 3px solid #5e5ad3;
        }
    }
    .nav-link {
        color: #0b2239;
    }
}

$profilePicSize: 32px;
.username {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .profilePic[data-letters]:before {
        position: absolute;
        content: attr(data-letters);
        display: inline-block;
        width: $profilePicSize;
        height: $profilePicSize;
        line-height: $profilePicSize;
        text-align: center;
        background: #41c0ed;
        vertical-align: middle;
        color: $white;
        border-radius: 50%;
        &.circle {
            border-radius: 4px;
        }
    }

    .profilePic {
        position: relative;
        display: inline-block;
        background: $white no-repeat center center;
        background-size: contain;
        border-radius: 50%;
        margin-right: 10px;
        width: $profilePicSize;
        height: $profilePicSize;
        margin-right: 0.5rem;
    }

    .avatar {
        position: absolute;
        display: inline-block;
        background-size: contain;
        border-radius: 50%;
        width: $profilePicSize;
        height: $profilePicSize;
    }
}
table {
    $tableProfilePicSize: 24px;
    .username {
        .profilePic[data-letters]:before {
            width: $tableProfilePicSize;
            height: $tableProfilePicSize;
            line-height: $tableProfilePicSize;
        }

        .profilePic,
        .avatar {
            width: $tableProfilePicSize;
            height: $tableProfilePicSize;
        }
    }
}
/*** LOADING **/

.typing-spinner {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    opacity: 1;
    height: 1.35em;
    top: 0.7em;
    -webkit-transition: opacity 0.1s;
    transition: opacity 0.1s;
    pointer-events: none;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    .ball {
        display: inline-block;
        width: 9px;
        height: 9px;
        margin-right: 5px;
        border-radius: 50%;
        background-color: $primary;

        // background-image: -webkit-linear-gradient(bottom left, #10b857, #17b7df); // @include linear-gradient(to right, $theme-g1, $theme-g2);

        &.white {
            background-image: none;
            background-color: #ffffff;
        }

        &:first-child {
            opacity: 0.9;
            -webkit-animation: typing-ball 0.9s linear infinite;
            animation: typing-ball 0.9s linear infinite;
        }

        &:nth-child(2) {
            opacity: 0.3;
            -webkit-animation: typing-ball 0.9s 0.225s linear infinite;
            animation: typing-ball 0.9s 0.225s linear infinite;
        }

        &:nth-child(3) {
            opacity: 0.6;
            -webkit-animation: typing-ball 0.9s 0.45s linear infinite;
            animation: typing-ball 0.9s 0.45s linear infinite;
        }
    }
}

@keyframes typing-ball {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(5px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}

/*** LOADING END **/
.table thead th {
    text-align: center;
    vertical-align: inherit;

    .form-control {
        height: calc(1.5em + 0.5rem + 2px);
        padding: 0.25rem 0.5rem;
        font-size: 12px;
        line-height: 1;
        border-radius: 0;
        color: #000;
    }
}
.scrollbar-container {
    .page {
        overflow-y: initial;
    }
}
.page {
    overflow-y: scroll;

    @media (min-width: 768px) {
        overflow: hidden;
        min-height: 100vh;
    }

    &.page-tests,
    &.page-testUpload,
    &.page-courses,
    &.page-courseUpload {
        background: $white;
    }

    // &.login-bg {
    //     &::after {
    //         content: '';
    //         width: 100vw;
    //         height: 840px;
    //         position: absolute;
    //         z-index: -1;
    //         bottom: 0;
    //         left: calc((1128px - 100vw) / 2);
    //         background: url('/assets/images/bg.svg') repeat-x bottom/auto 100%;
    //     }
    // }

    .login-form {
        border: 1px solid #0000000f;
        border-radius: 0.4rem;
    }

    &.page-course {
        padding-top: 2rem;

        h1 {
            @media (max-width: 767px) {
                font-size: 1.5rem;
                line-height: 1rem;
            }

            font-size: 2rem;
            line-height: 3rem;
        }

        .sidemenu-wrapper {
            .promo {
                text-align: center;
                border: 1px solid #e8e8e8;
                // padding: 1rem 1.5rem;
                padding: 1rem 0.5rem;
                border-radius: 0.25rem;

                img {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 1rem;
                }

                h2 {
                    font-size: 1.2rem;
                }

                ul {
                    list-style: none;
                    padding: 0;

                    a {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

    &.page-course-preview {
        background: $white;

        .containerr {
            padding: 2rem 0;
        }

        section {
        }

        h1 {
            font-size: 2rem;
            line-height: 3rem;
        }

        h2 {
            font-size: 1.5rem;
            line-height: 2.5rem;
        }

        .desc {
        }

        .readMore {
            color: $primary;
            cursor: pointer;
        }

        .list-group-item {
            cursor: pointer;
        }

        .collapse.show {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
        }
    }

    &.page-player {
        overflow-y: scroll;

        @media (min-width: 768px) {
            height: 100vh;
            background-color: #363636;
            overflow: hidden;
        }
    }
}

.topic-icon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.type-video::before {
        content: '\f144';
    }

    &.type-article::before {
        content: '\f15c';
    }

    &.type-quiz::before {
        content: '\f059';
    }

    &.type-test::before {
        content: '\f03a';
    }
}

.course-card {
    $card-br: 0.3rem;
    border-radius: $card-br;
    box-shadow: 0px 4px 4px 0px #dcebf7;
    border: none;
    margin-bottom: 1rem;

    &.card:hover {
        box-shadow: 0px 4px 4px 0px #c0ddf5;
    }

    .course-img {
        position: relative;
        width: 100%;
        height: 150px;
        background: rgba(0, 0, 0, 0.01) no-repeat;
        background-position: center;
        background-size: cover;
        border-top-left-radius: $card-br;
        border-top-right-radius: $card-br;

        .course-img-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-top-left-radius: $card-br;
            border-top-right-radius: $card-br;
            // background: rgba(0, 0, 0, 0.5)
        }
    }

    .progress {
        height: 5px;

        .progress-bar {
            height: 5px;
            // width:70%;
            background-color: $primary;
        }
    }

    //  courses
    &.course-card-single {
        border-radius: 0.3rem;
        border: none;
        margin-bottom: 1rem;
        padding-bottom: 15px;
        box-shadow: rgb(224, 224, 224) 0px 4px 0.9em;
        font-family: Avenir;
        position: relative;
        cursor: pointer;
        border-radius: 17px;
        transition: all 0.2s ease 0s;
        background: $white;

        @media (max-width: 767px) {
        }

        &.card {
            width: 290px;
            min-width: 290px;
            box-shadow: rgb(224, 224, 224) 0px 4px 0.9em;
            position: relative;
            cursor: pointer;
            margin: 1rem 1rem 1rem 0;
            border-radius: 17px;
            transition: all 0.2s ease 0s;
            font-family: Helvetica Neue, sans-serif;

            @media (max-width: 767px) {
                margin: 1.5rem 0rem;
            }

            .code-body {
                padding: 1.25rem 2rem;

                @media (max-width: 767px) {
                    padding: 1.25rem;
                }
            }
        }

        .course-img,
        .course-img-overlay {
            border-top-left-radius: 17px;
            border-top-right-radius: 17px;
        }

        .card-title {
            color: #353535;
            font-family: Helvetica Neue, sans-serif;
            font-weight: 700;
            margin: 1rem 0;

            @media (max-width: 767px) {
                margin: 0.5rem 0;
            }
        }

        .category {
            background-color: rgb(126, 211, 33);
            color: $white;
            font-weight: bold;
            font-size: 14px;
            text-transform: lowercase;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
            padding: 1px 13px 1px;
            border-radius: 20px;
            border-width: 2px;
            border-style: solid;
            border-color: #d0ecb0;
            border-image: initial;
            top: 0;
            right: 0;
            position: absolute;
            margin: 12px;

            @media (max-width: 767px) {
                margin: 10px;
            }
        }

        .tags {
            padding: 0.5rem 0;

            .tag {
                display: inline-block;
                color: rgb(119, 119, 119);
                font-weight: 700;
                background-color: rgb(246, 246, 246);
                border-radius: 15px;
                padding: 5px 15px;
                margin: 0 0.3rem 0.3rem 0;
            }
        }

        .totalLessons {
            color: rgb(75, 73, 255) !important;
            text-transform: uppercase;
            font-size: 12px;
            text-transform: uppercase;
            padding: 0 0 1rem 0;
            display: block;

            @media (max-width: 767px) {
                padding: 0 0 0.5rem 0;
            }
        }

        .btn-outline {
            color: rgb(75, 73, 255);
            background-color: white;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            border-radius: 99px;
            border: 1px solid rgb(75, 73, 255);
            border-image: initial;
            padding: 11px 45px;
            margin: 15px 0px;
            transition: all 0.2s ease 0s;

            @media (max-width: 767px) {
                padding: 5px 35px;
                margin: 10px 0px;
            }

            &:hover,
            &:focus {
                transform: scale(1.01);
            }

            &:hover,
            &:focus {
                color: $white;
                background: rgb(75, 73, 255);
                outline: none;
            }
        }
    }
}

.course-player {
    .contents-heading {
        background: black;
        color: $white;
        padding: 10px;
        float: left;
        width: 100%;
        margin-bottom: 0;

        span:first-child {
            float: left;
        }

        span:last-child {
            float: right;
        }

        .nav-link {
            background: inherit;
            border: inherit;
            color: inherit;
        }
    }

    .notes-div {
        height: 70vh;
    }
}

.player-wrapper {
    position: relative;
    // background-image: url(https://d1c64qna5hpf1i.cloudfront.net/card/1.jpg);
    // background-repeat: no-repeat;
    // background-size: contain;

    padding-top: 19.25%;

    @media (min-width: 768px) {
        padding-top: 56.25%;
    }

    .react-player {
        position: relative;

        @media (min-width: 768px) {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.questions {
    .q-active {
        text-decoration: underline;
        color: blue;
    }

    .gray-border {
        border: 1px solid lightgray;
    }
}

#app {
    height: 100vh;
    -webkit-overflow-scrolling: touch;
}

.player-container {
    flex: 1;
    background: #000;
    position: relative;
}

.video-header {
    height: 100px;
    width: 100%;
    z-index: 100;
    transition: opacity 0.2s ease-in-out;
}

.ps-sidemenu-enter {
    margin-right: -22.5rem;
    transition: margin-right 0.3s ease-in-out;
}

.ps-sidemenu-enter.ps-sidemenu-enter-active {
    margin-right: 0;
}

.ps-sidemenu-exit {
    margin-right: 0;
    transition: margin-right 0.3s ease-in-out;
}

.ps-sidemenu-exit.ps-sidemenu-exit-active {
    margin-right: -22.5rem;
}

.ps-modal,
.video-header {
    position: absolute;
    top: 0;
    left: 0;
}

.gradient-fade {
    min-height: 100px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0, transparent);
}

.fade-out.video-header {
    opacity: 0;
    transition-delay: 0ms;
}

.ps-color-white,
.ps-button-primary-lg,
.ps-button-primary-md,
.ps-button-primary-sm {
    color: #fff;
}

.ps-type-xs {
    font-size: 0.75rem;
}

.ps-color-gray-02 {
    color: #aaa;
}

.pr-sm {
    padding-right: 1rem !important;
}

.pt-tiny {
    padding-top: 0.25rem !important;
}

.ps-type-lg {
    font-size: 1.5rem;
}

.ps-type-tiny {
    font-size: 0.625rem;
}

.py-tiny {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.ps-color-border-gray-02 {
    border: 1px solid #aaa;
}

.ps-color-gray-02 {
    color: #aaa;
}

.button-with-floating-content {
    position: relative;
}

.menu-index {
    z-index: 500;
}

.ps-color-gray-04 {
    color: #363636;
}

.ps-color-bg-white {
    background-color: #fff;
}

.icobutton {
    height: 3.5rem;
    padding: 0.5rem;
    border: none;
    background: none;
    // font-size: 1.5rem;
    color: #aaa;
    cursor: pointer;
    transition: all 0.2s;
}

.requires-fullscreen {
    display: none;
}

html.fullscreen .requires-fullscreen {
    display: inherit;
}

#video {
    flex: 1;
    align-items: center;
}

#video > div,
#video video {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
}

.video-footer {
    z-index: 200;
    position: absolute;
    bottom: 0;
    left: 0;
}

.control-bar {
    transition: height 0.2s;
    height: 3.5rem;
}

.fade-out.control-bar {
    height: 0;
    transition-delay: 0.2s;
}

.pt-duration {
    min-width: 40px;
    display: inline-block;
    text-align: right;
}

.progress1 {
    transition: all 0.2s;
    position: relative;
    top: 0;

    // height: 4px;
    // overflow: hidden;
    // font-size: 0.75rem;
    // border-radius: 0;

    .hidden-bar {
        top: -15px;
        background-color: #fff;
        z-index: 301;
        opacity: 0;
        height: 25px;
        position: absolute;
        width: 100%;
        left: 0;
        cursor: pointer;
    }

    #progress-bar {
        height: 4px;
        width: 100%;
        position: absolute;
        top: -4px;
        left: 0;
        box-sizing: border-box;
        z-index: 300;
        background-color: #555;
        cursor: pointer;
        transition: all 0.2s;

        #buffering-range {
            position: absolute;
            top: 0;
            background-color: #aaa;
            height: 100%;
        }

        .hidden-bar:hover + #progress-bar {
            top: -8px;
            height: 8px;
        }

        .bar {
            height: 100%;
            width: 0;
            background-color: #f96816;
            position: relative;

            .handle {
                opacity: 0;
                height: 0;
                width: 0;
                border-radius: 8px;
                background-color: #fff;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 302;
                box-shadow: 0 0 3px #333;
                transition: all 0.1s ease-in-out 0.1s;
            }
        }
    }
}

.progress1 .hidden-bar {
    top: -15px;
    background-color: #fff;
    z-index: 301;
    opacity: 0;
    height: 25px;
    position: absolute;
    width: 100%;
    left: 0;
    cursor: pointer;
}

.progress1 .hidden-bar:hover + #progress-bar .bar .handle {
    opacity: 1;
    width: 16px;
    height: 16px;
    top: -4px;
    right: -8px;
}

.progress1 .hidden-bar:hover + #progress-bar {
    top: -8px;
    height: 8px;
}

.progress1 .hidden-bar:hover + #progress-bar .note-marker-tick-mark {
    height: 0.5rem;
}

.markers-hidden {
    opacity: 0;
    transition: opacity 0.6s;
}

.markers-visible {
    transition: opacity 0.2s;
}

.note-marker-container {
    position: absolute;
    display: inline-block;
    bottom: 0.625rem;
    z-index: 300;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.note-marker-exponent-hidden {
    display: none;
}

.icon-note-marker,
.icon-pen {
    background-repeat: no-repeat;
    background-position: 50%;
}

.icon-note-marker {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEzcHgiIGhlaWdodD0iMTVweCIgdmlld0JveD0iMCAwIDEzIDE1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgMy44LjMgKDI5ODAyKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT42Nzk5NzM5Ni02QzQ1LTRFNkItQUQxRC0yMzU0RDA0NTg4RTc8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIHNrZXRjaHRvb2wuPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ii1ub3Rlcy1zdHlsZXMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNjEuMDAwMDAwLCAtOTQzLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iaWNvbi10aW1lbGluZS1ub3RlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNjEuMDAwMDAwLCA5NDMuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aC00MDQiIGZpbGw9IiMwMDAwMDAiIG9wYWNpdHk9IjAuMjUyMTU3MTgzIiBwb2ludHM9IjkuNDU0NTQ1NDUgMCAxMyAzLjQ2MTUzODQ2IDEzIDQuMDM4NDYxNTQgMTMgMTUgMCAxNSAwIDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yLDEzIEwxMSwxMyBMMTEsNC41IEw4LjUsMiBMMiwyIEwyLDEzIFogTTksMSBMMTIsNCBMMTIsMTQgTDEsMTQgTDEsMSBMOSwxIFoiIGlkPSJQYXRoLTQwNCIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTMsMyBMOCwzIEw4LDQgTDMsNCBMMywzIFogTTMsNSBMMTAsNSBMMTAsNiBMMyw2IEwzLDUgWiBNMyw3IEwxMCw3IEwxMCw4IEwzLDggTDMsNyBaIE0zLDkgTDEwLDkgTDEwLDEwIEwzLDEwIEwzLDkgWiBNMywxMSBMMTAsMTEgTDEwLDEyIEwzLDEyIEwzLDExIFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
    width: 13px;
    height: 15px;
}

.note-marker-button:hover {
    cursor: pointer;
}

.note-marker-container:hover {
    opacity: 1;
}

.note-marker-button {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50%;
    border: none;
}

.note-marker-exponent-shown {
    width: 0.5rem;
    text-shadow: 0 0 0.125rem #000;
    position: absolute;
    top: 0;
    right: -0.625rem;
}

.tick-container {
    position: absolute;
    top: -0.375rem;
    z-index: 301;
    background: transparent;
}

.note-marker-tick-mark {
    transition: height 0.2s;
    position: absolute;
    top: 0.375rem;
    height: 0.25rem;
    width: 0.125rem;
}

.note-entry-box .editor-and-timestamp {
    transition: margin-bottom 0.2s ease-in-out;
    position: relative;
    margin-bottom: 0;
    z-index: 401;
}

.note-entry-box.enter-to-save-enabled .editor-and-timestamp {
    margin-bottom: -3.125rem;
}

.note-entry-box .DraftEditor-root,
.phantom-border-fix {
    margin-bottom: -0.0625rem;
    padding-bottom: 0.0625rem;
}

.note-entry-box .DraftEditor-root {
    border-radius: 0.125rem;
    padding: 0.75rem;
    max-height: 25rem !important;
    overflow-y: auto;
    box-sizing: border-box;
    position: relative;
}

.note-entry-box .public-DraftEditorPlaceholder-root {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    z-index: 0;
    color: #aaa;
}

@media (min-width: 768px) {
    .hidden-md-up {
        display: none !important;
    }
}

.fa-side-close:before {
    content: '\f061';
}

.fa-side-open:before {
    content: '\f060';
}

.nav-tabs {
    padding: 1rem 0 0 2rem;
    letter-spacing: -0.05em;
    text-rendering: optimizeLegibility;
    word-wrap: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    margin: 0;
    max-width: 100%;
    border-bottom: 1px solid #181818;
    list-style-type: none;
    margin-bottom: 0;
}

.nav-tabs .nav-link {
    float: left;
    margin: 0;
    margin-right: 2rem;
    border: none;
    background-color: transparent;
    color: $white;
    cursor: pointer;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    color: $white !important;
}

.nav-tabs .nav-link.active {
    background-color: transparent;
    color: $white !important;
    border: none;
    border-bottom: 4px solid #f96816;
}

.controls {
    height: 3.5rem;
    background: rgba(0, 0, 0, 1);
    position: relative;

    .controls-left,
    .controls-mid,
    .controls-right {
        transition: opacity 0.2s;
    }

    .controls-left {
        overflow: hidden;
        position: relative;
    }

    .fa-prev::before {
        content: '\f048';
    }

    .fa-next::before {
        content: '\f051';
    }

    .fa-rew::before {
        content: '\f050';
    }

    .fa-ff::before {
        content: '\f049';
    }

    .fa-note-add::before {
        content: '\f249';
    }

    .fa-vol-on::before {
        content: '\f028';
    }

    .fa-vol-off::before {
        content: '\f026';
    }

    .fa-vol-down::before {
        content: '\f027';
    }

    .fa-cc-on::before {
        content: '\f20a';
    }

    .fa-cc-off::before {
        content: '\f20a';
    }

    .fa-setting::before {
        content: '\f013';
    }

    .fa-full-open::before {
        content: '\f31e';
    }

    .add-note-button {
        height: 2rem;
        min-width: 2rem;
        max-width: 6rem;
        margin: 0.75rem;
        padding: 0 0.25rem;
        border: 1px solid #555;
        border-radius: 0.125rem;
    }

    .add-note-button-label {
        overflow: hidden;
        white-space: nowrap;
    }

    .playback-rate-control-button {
        margin: 0;
        padding: 0;
    }

    .playback-rate-control .playback-rate-control-text {
        transition: all 0.2s;
        background: transparent;
        box-sizing: border-box;
        text-align: center;
        width: 2rem;
        cursor: pointer;
    }
}

@media (max-width: 767px) {
    .hidden-sm-down {
        display: none !important;
    }
}

.progress .hidden-bar:hover + #progress-bar {
    top: -8px;
    height: 8px;
}

.fade-out .controls-left,
.fade-out .controls-mid,
.fade-out .controls-right {
    opacity: 0;
    transition-delay: 0.1s;
}

#side-menu {
    @media (min-width: 768px) {
        width: 22.5rem;
        height: 100vh;
        overflow: hidden;
    }
}

#side-menu {
    position: relative;

    @media (min-width: 768px) {
        z-index: 300;
        flex-direction: column;
    }

    #tab-table-of-contents {
        overflow-y: auto;
        height: calc(100vh - 60px);

        @media (max-width: 767px) {
            overflow-y: auto;
            height: auto;
        }
    }

    .tab-pane.active {
        @media (max-width: 767px) {
            overflow-y: auto;
            // overflow-y: scroll;
            // height: calc(100vh - 60px);
            // background: red;
        }
    }
}

.over-y-scrl {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 260px);
}

.footer-fixed {
    @media (max-width: 767px) {
        position: relative;
        width: 100%;
        /* bottom: 20px; */
        // background: #4CAF50;
        display: flex;
        flex-direction: column;
        // height: 100vh;
        // height: calc(100vh - 150px);
        padding: 2rem 0;
    }

    position: fixed;
    bottom: 20px;
    background: #363636;

    @media (min-width: 768px) {
        width: 46%;
    }

    @media (min-width: 1024px) {
        width: 35%;
    }

    @media (min-width: 1366px) {
        width: 26.5%;
    }
}

.ps-color-bg-gray-04 {
    background-color: #363636;
}

.module {
    &.open {
        .clips {
            height: auto;
        }
    }

    header {
        border-bottom: 1px solid #181818;
    }

    .clips {
        height: 0;
        overflow: hidden;
    }
}

.side-menu-clip-progress,
.side-menu-module-progress {
    width: 4rem;
}

.side-menu-module-title {
    flex: 1;
}

.side-menu-clip-title {
    flex: 1;
}

.ps-type-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ps-type-xs {
    font-size: 0.75rem;
}

.ps-type-weight-book {
    font-weight: 400;
}

.ps-type-lg {
    font-size: 1.5rem;
}

.ps-color-gray-02 {
    color: #aaa;
}

.p-md {
    padding: 1rem 1rem !important;
}

.ps-color-bg-gray-05 {
    background-color: #222;
}

.ps-color-green {
    color: #23aa5a;
}

.rounded-circle {
    border-radius: 50%;
}

.ps-color-white,
.ps-button-primary-lg,
.ps-button-primary-md,
.ps-button-primary-sm {
    color: #fff;
}

.ps-type-weight-medium {
    font-weight: 500;
}

.ps-type-sm,
.ps-button-primary-md,
.ps-button-primary-sm,
.ps-button-stroke-md,
.ps-button-stroke-sm {
    font-size: 1rem;
}

.ps-color-gray-02 {
    color: #aaa;
}

.ps-type-weight-book {
    font-weight: 400;
}

.ps-type-tiny {
    font-size: 0.625rem;
}

.py-md {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-sm {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.pr-lg {
    padding-right: 1.5rem !important;
}

.ps-color-gray-02 {
    color: #aaa;
}

.ps-color-orange,
.ps-button-stroke-lg,
.ps-button-stroke-md,
.ps-button-stroke-sm {
    color: #f96816;
}

.ps-color-bg-gray-05 {
    background-color: #222;
}

.side-menu-clip {
    cursor: pointer;
    background-image: url(/assets/courses/clip-bg.svg);
    background-repeat: repeat-y;
}

.side-menu-clip:last-child {
    background-image: url(/assets/courses/clip-bg-last.svg);
}

.module header:hover:not(.active-module) {
    background-color: #555;
    transition: background-color 0.2s;
}

.viz-container {
    position: relative;
}

.viz,
.viz-container {
    width: 1.5rem;
    height: 1.5rem;
}

.nav-tabs .nav-link {
    color: #aaa !important;
    display: block;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
}

.side-menu-clip-unwatched-icon {
    width: 10px;
    height: 10px;
}

.side-menu-clip:hover:not(.selected) {
    color: #fff;
}

.contents-heading {
    color: $white;
    padding: 1rem;
    text-align: center;
}

.textareaNotes {
    textarea {
        border-radius: 0;
    }
}

.ps-color-border-gray-02 {
    border: 1px solid #aaa;
}

.px-tiny {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.mx-tiny {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
    box-sizing: border-box;
}

.tgl {
    display: none;
}

.tgl-light:checked + .tgl-btn {
    background: #f96816;
}

.tgl-light + .tgl-btn {
    background: #555;
    border-radius: 2em;
    padding: 0px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 26px;
    height: 13px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ps-button-primary-md {
    box-sizing: border-box;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    transition: all 300ms ease-out;
    height: 2.5rem;
}

.note-entry-box .save-note-button {
    transition: opacity 0.2s ease-in-out 50ms;
    position: relative;
    opacity: 1;
    z-index: 400;
    border: 1px solid #f96816;
    color: #fff;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
    position: absolute;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
}

.tgl + .tgl-btn:after {
    left: 0;
}

.tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #fff;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.tgl:checked + .tgl-btn:after {
    left: 50%;
}

.my-cutsom {
    margin: 3.5rem 0;
}

.ps-color-border-bottom-gray-06 {
    border-bottom: 1px solid #181818;
}

.download-notes,
.view-all-notes {
    transition: opacity 0.2s;
    opacity: 0.6;
    cursor: pointer;
    color: #fff;
    font-size: 0.95rem;

    &:hover {
        opacity: 1;
        color: #fff;
    }
}

.ps-divide-pipe {
    width: 0.0625rem;
}

.ps-color-bg-gray-05 {
    background-color: #222;
}

.pl-md {
    padding-left: 1rem !important;
}

.notes-list {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.note-item {
    overflow-x: hidden;
    transition: background-color 0.2s;
}

// .ps-type-sm,
// .ps-button-primary-md,
// .ps-button-primary-sm,
// .ps-button-stroke-md,
// .ps-button-stroke-sm {
//     font-size: .875rem;
//     color: #fff;
// }

.ps-type-xs {
    font-size: 0.75rem;
}

.ps-color-gray-02 {
    color: #aaa;
}

.ps-type-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.note-item-edit-label {
    min-width: 8rem;
    margin-right: -8rem;
    transition: margin-right 0.2s;
}

.ps-color-orange,
.ps-button-stroke-lg,
.ps-button-stroke-md,
.ps-button-stroke-sm {
    color: #f96816;
}

.px-md,
.ps-button-primary-lg,
.ps-button-primary-md,
.ps-button-primary-sm,
.ps-button-stroke-lg,
.ps-button-stroke-md,
.ps-button-stroke-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.standard-note-item {
    &:hover {
        background-color: #484848;
        cursor: pointer;
    }

    i.fa.fa-play-circle {
        color: #f96816;
    }
}

.standard-note-item:hover .note-item-edit-label {
    margin-right: 0;
}

.rc-slider-vertical {
    height: 100%;
}

.floating-content {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    white-space: nowrap;
}

.volume-slider-wrapper {
    width: 2rem;
    position: relative;
    height: 6rem;
    padding: 1rem 0;
}

.rc-slider,
.rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #fff;
}

.rc-slider-vertical .rc-slider-rail,
.rc-slider-vertical .rc-slider-track {
    height: 100%;
    width: 0.125rem;
    margin-left: 0.9375rem;
}

.rc-slider-track {
    position: absolute;
    background-color: #f96816;
}

.rc-slider-vertical .rc-slider-rail,
.rc-slider-vertical .rc-slider-track {
    height: 100%;
    width: 0.125rem;
    margin-left: 0.9375rem;
}

.rc-slider-handle {
    position: absolute;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
}

.rc-slider-vertical .rc-slider-handle {
    margin-bottom: -0.625rem;
    margin-left: 0.5rem;
}

// Quiz modal-block
.next-topic-start {
    position: relative;

    .modal-block {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        flex-grow: 1;
        background: #fff;
        width: 100%;
        flex-direction: column;
        max-width: 413px;
        margin: 70px auto;
        vertical-align: middle;
        padding: 2rem 0;
        text-align: center;

        @media (min-width: 768px) {
            border-radius: 11px;
            padding: 2rem 0 4rem;
        }

        // background: #e6dfdf;
    }

    p {
        font-size: 1.5rem;
        font-weight: 300;
        margin: 0 0 1.5rem 0;
    }

    .btn {
        padding: 6px 2rem;
        border-radius: 0;
    }
}

// .username {
//     display: flex;
//     align-items: center;
//     justify-content: start;

//     .icon {
//         width: 25px;
//         height: 25px;
//         border-radius: 4px;
//         background-size: cover;
//         display: inline-block;
//     }
// }

.invalid-field {
    color: $danger;
}
.letter-A,
.letter-a {
    background-color: #845ec2 !important;
}
.letter-B,
.letter-b {
    background-color: #d65db1 !important;
}
.letter-C,
.letter-c {
    background-color: #ff6f91 !important;
}
.letter-D,
.letter-d {
    background-color: #ff9671 !important;
}
.letter-E,
.letter-e {
    background-color: #ffc75f !important;
}
.letter-F,
.letter-f {
    background-color: #f9f871 !important;
}
.letter-G,
.letter-g {
    background-color: #4b4453 !important;
}
.letter-H,
.letter-h {
    background-color: #b0a8b9 !important;
}
.letter-I,
.letter-i {
    background-color: #c34a36 !important;
}
.letter-J,
.letter-j {
    background-color: #ff8066 !important;
}
.letter-K,
.letter-k {
    background-color: #936c00 !important;
}
.letter-L,
.letter-l {
    background-color: #008ccc !important;
}
.letter-M,
.letter-m {
    background-color: #07b65c !important;
}
.letter-N,
.letter-n {
    background-color: #00a277 !important;
}
.letter-O,
.letter-o {
    background-color: #008c85 !important;
}
.letter-P,
.letter-p {
    background-color: #007583 !important;
}
.letter-Q,
.letter-q {
    background-color: #005e73 !important;
}
.letter-R,
.letter-r {
    background-color: #2f4858 !important;
}
.letter-S,
.letter-s {
    background-color: #00ab8c !important;
}
.letter-T,
.letter-t {
    background-color: #009db5 !important;
}
.letter-U,
.letter-u {
    background-color: #008acb !important;
}
.letter-V,
.letter-v {
    background-color: #0072c9 !important;
}
.letter-W,
.letter-w {
    background-color: #4c56ae !important;
}
.letter-X,
.letter-x {
    background-color: #b90a00 !important;
}
.letter-Y,
.letter-y {
    background-color: #d3005a !important;
}
.letter-Z,
.letter-z {
    background-color: #c232aa !important;
}

.letter-0 {
    background-color: #786be8 !important;
}
.letter-1 {
    background-color: #0091ff !important;
}
.letter-2 {
    background-color: #00aaf3 !important;
}
.letter-3 {
    background-color: #cf03bc !important;
}
.letter-4 {
    background-color: #605ff8 !important;
}
.letter-5 {
    background-color: #0082ff !important;
}
.letter-6 {
    background-color: #0091ed !important;
}
.letter-7 {
    background-color: #0096af !important;
}
.letter-8 {
    background-color: #009667 !important;
}
.letter-9 {
    background-color: #0087ed !important;
}

.education-details {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    $eduSize: 50px;
    $eduRadius: 4px;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    h5 {
        font-size: 1rem;
        font-weight: bold;
    }
    p {
        margin-bottom: 0.5rem;
    }
    .duration {
        font-size: 0.8rem;
    }

    .placeholder[data-letters]:before {
        position: absolute;
        content: attr(data-letters);
        display: inline-block;
        width: $eduSize;
        height: $eduSize;
        line-height: $eduSize;
        text-align: center;
        // background-color: #41c0ed;
        vertical-align: middle;
        color: $white;
        border-radius: $eduRadius;
    }

    .placeholder {
        position: relative;
        display: inline-block;
        width: $eduSize;
        height: $eduSize;
        background: $white no-repeat center center;
        background-size: contain;
        border-radius: $eduRadius;
        margin-right: 10px;
    }

    .title {
        max-width: calc(100% - 50px);
    }

    .icon {
        position: absolute;
        display: inline-block;
        width: $eduSize;
        height: $eduSize;
        background-size: contain;
        border-radius: $eduRadius;
    }
}
