@import 'styles/variables.scss';
@import '~rc-drawer/assets/index.css';
@import '~react-dropdown-tree-select/dist/styles.css';

.empty-data {
    margin: 80px auto;
    text-align: center;

    img {
        width: 67px;
        height: 67px;
    }

    p {
        color: #191d23;
        font-weight: 500;
        letter-spacing: 0.1px;
        font-size: 18px;
        line-height: 1.3;
        text-transform: initial;
        margin-top: 20px;
    }
}

$notActiveColor: #303030;
$subLinkNotActiveColor: #969696;
$activeColor: $primary;
.sidemenu {
    .navbar-nav {
        width: 100%;
        .nav-item {
            &:focus,
            &:hover,
            &:active,
            &.active {
                background: white;
                outline: none;
                box-shadow: none;
                border: none;
            }
            .nav-link {
                align-items: center;
                display: flex;
                color: $notActiveColor;
                font-size: 14px;
                line-height: 24px;
                padding: 12px 20px;
                padding-left: 20px;

                i {
                    margin-right: 0.5rem;
                }
                &.active,
                &.active i {
                    color: $activeColor !important;
                    font-weight: 600;
                }
            }
        }
        .nav-header {
            padding: 12px 20px;
            padding-right: 4rem;
            color: $notActiveColor;
            font-size: 14px;
            line-height: 24px;
            // font-weight: 600;
            cursor: pointer;
            &.forcedCollapse {
                padding-right: 20px;
                &::after,
                &.active::after {
                    content: '';
                    background-image: none;
                }
            }
            .headerTitle {
                i {
                    margin-right: 0.5rem;
                }
            }
            &::after {
                display: inline-block;
                position: absolute;
                right: 19px;
                content: '';
                background-image: url('/assets/icons/angle-down.svg');
                width: 24px;
                height: 24px;
                color: $notActiveColor;
                font-size: 14px;
                line-height: 24px;
                // font-weight: 600;
                cursor: pointer;
            }
            $rotate: 180deg;

            &.active {
                // color: $activeColor;

                &::after {
                    color: $notActiveColor;
                    -webkit-transform: rotate($rotate);
                    -moz-transform: rotate($rotate);
                    -ms-transform: rotate($rotate);
                    -o-transform: rotate($rotate);
                    transform: rotate($rotate);
                }
            }

            .nav-item {
                cursor: pointer;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 19px;
                    right: 32px;
                    // width: 0px;
                    // height: 0px;
                    // border-left: 4px solid transparent;
                    // border-right: 4px solid transparent;
                    // border-top: 6px solid $primary;
                    margin: 0 auto;
                    transition: 0.5s all ease;
                }
                &.active {
                    &:after {
                        border-top: 6px solid $notActiveColor;
                        transform: rotate(180deg);
                        transition: 0.5s all ease;
                    }
                }
            }
        }
        ul {
            list-style: none;
            // padding-inline-start: 20px;
            .nav-item .nav-link {
                padding: 12px 0;
                color: $subLinkNotActiveColor;
            }
        }
        h5 {
            font-size: 0.7rem;
            font-weight: 900;
            margin: 9px 0;
        }
        .nav-header.active {
            background: #f7f7f7 !important;
            // color: #303030;
        }
        .custom-control-label {
            display: block;
            color: $notActiveColor;
            font-size: 14px;
            line-height: 24px;
            // padding: 12px 20px;
            // padding-left: 1.5rem;
        }
    }
}

.page-userUpload {
    background: white;

    .fileBlock {
        .input-group {
            width: 58%;
        }
    }
}

.page-users,
.page-tests,
.page-courses,
.page-clients,
.page-groups {
    background: white;

    h1 {
        font-size: 25px;
        line-height: inherit;
        margin-bottom: 0;
    }

    .btn-primary {
        font-size: 12px;
        padding: 10px 25px;
        border-radius: 35px;
        outline: 0;
        color: #fff;
        cursor: pointer;
        letter-spacing: 1px;

        @media (max-width: 767px) {
            margin: 0.5rem 0;
        }
    }

    .react-bootstrap-table {
        &.table-responsive {
            padding: 1rem 0;

            @media (min-width: 1200px) {
                overflow-x: hidden;
            }

            .table {
                @media (max-width: 768px) {
                    width: auto;
                }
            }
            thead {
                tr {
                    color: grey;
                    font-size: 12px;
                    line-height: 22px;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    font-weight: 700;
                    // padding: 8px;
                    border: none;
                    border-bottom: 1px solid #ccc;

                    th {
                        padding-bottom: 1rem;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }

            tbody {
                td {
                    display: table-cell;
                    vertical-align: middle;
                    font-size: 14px;
                    line-height: 24px;
                    text-transform: initial;
                    padding: 8px;
                }

                tr:nth-child(even) {
                    background: #f6f7f8;

                    &:hover {
                        background: #ecf0f3;
                    }
                }

                tr:nth-child(odd) {
                    background: #fff;

                    &:hover {
                        background: #ecf0f3;
                    }
                }
            }

            tr {
                cursor: pointer;
                border-bottom: 1px solid #eeeeee;
            }

            th,
            .table-bordered td {
                border: none;
            }

            th {
                @media (max-width: 767px) {
                    min-width: 133px;
                }
            }
        }
    }

    th,
    td {
        width: 100%;
        text-align: left !important;
    }

    .border-b {
        border-bottom: 2px solid #ddd;
        padding: 0 0 1rem;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    .heading-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        .form-control {
            margin-left: 50px;
            height: 40px;
            width: 300px;
            padding-left: 37px;
            border-radius: 35px;
            border: 2px solid transparent;
            background-color: #eee;
            transition: border-color 0.5s, background-color 0.5s;
            border: 1px solid #eee;

            @media (max-width: 767px) {
                margin-left: 30px;
                height: 40px;
                width: 200px;
            }

            &:hover,
            &:focus {
                outline: none;
                box-shadow: none;
                border: 1px solid #5e5ad3;
                background-color: #fff;
            }
        }

        .search-block {
            position: relative;
        }

        .fa-search {
            position: absolute;
            pointer-events: none;
            left: 19%;
            top: 30%;
        }
    }
}

.Pane.vertical {
    overflow: auto;
}
// .Resizer {
//     background: #000;
//     opacity: 0.2;
//     z-index: 1;
//     -moz-box-sizing: border-box;
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
//     -moz-background-clip: padding;
//     -webkit-background-clip: padding;
//     background-clip: padding-box;
// }

// .Resizer:hover {
//     -webkit-transition: all 2s ease;
//     transition: all 2s ease;
// }

// .Resizer.horizontal {
//     height: 11px;
//     margin: -5px 0;
//     border-top: 5px solid rgba(255, 255, 255, 0);
//     border-bottom: 5px solid rgba(255, 255, 255, 0);
//     cursor: row-resize;
//     width: 100%;
// }

// .Resizer.horizontal:hover {
//     border-top: 5px solid rgba(0, 0, 0, 0.5);
//     border-bottom: 5px solid rgba(0, 0, 0, 0.5);
// }

// .Resizer.vertical {
//     width: 11px;
//     margin: 0 -5px;
//     border-left: 5px solid rgba(255, 255, 255, 0);
//     border-right: 5px solid rgba(255, 255, 255, 0);
//     cursor: col-resize;
// }

// .Resizer.vertical:hover {
//     border-left: 5px solid rgba(0, 0, 0, 0.5);
//     border-right: 5px solid rgba(0, 0, 0, 0.5);
// }
// .Resizer.disabled {
//     cursor: not-allowed;
// }
// .Resizer.disabled:hover {
//     border-color: transparent;
// }

.clientname {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .placeholder[data-letters]:before {
        position: absolute;
        content: attr(data-letters);
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        // background: #41c0ed;
        background: var(--tooltip-color);
        vertical-align: middle;
        color: $white;
        border-radius: 4px;
        &.circle {
            border-radius: 50%;
        }
    }

    .placeholder {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        background: $white no-repeat center center;
        background-size: contain;
        border-radius: 50%;
        margin-right: 10px;
    }

    .title {
        max-width: calc(100% - 50px);
    }

    .icon {
        position: absolute;
        display: inline-block;
        width: 32px;
        height: 32px;
        background-size: contain;
        border-radius: 50%;
    }
}

.drawer-right.drawer-open {
    .clientname .title {
        font-size: 20px;
        font-weight: bold;
    }
}
// page-client-preview
.page-client-preview,
.page-user-preview {
    h6 {
        font-weight: 700;
        // color: #2196f3;
        margin-top: 1rem;
    }

    .description {
        margin-bottom: 0.5rem;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 24px;

        .desc-item-label {
            position: relative;
            font-weight: bold;
            color: #5e5ad3;
            &:after {
                position: relative;
                top: -0.5px;
                margin: 0 8px 0 2px;
                content: ':';
                font-weight: bold;
            }
        }
        .desc-item-empty {
            color: #ccc;
            font-size: 0.8rem;
            font-style: italic;
        }
    }

    .table.stats {
        th {
            // color: grey;
            color: #5e5ad3;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            font-weight: bold !important;
            border-bottom: 1px solid #ccc;
        }
        td {
            text-align: center;
        }
    }
}
.label-details span:before {
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
    content: ':';
    font-weight: bold;
}

.ad-sidemenu {
    // .logo {
    //     height: 2rem;
    // }
}
.icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    &.icon-dashboard {
        background-image: url('/assets/icons/admin-dashboard.svg');
    }

    &.icon-university {
        background-image: url('/assets/icons/admin-university.svg');
    }
    &.icon-elearning {
        background-image: url('/assets/icons/admin-elearning.svg');
    }
    &.icon-test {
        background-image: url('/assets/icons/admin-test.svg');
    }
    &.icon-users {
        background-image: url('/assets/icons/admin-users.svg');
    }
    &.icon-calendar {
        background-image: url('/assets/icons/admin-calendar.svg');
    }
    &.icon-classroom {
        background-image: url('/assets/icons/admin-classroom.svg');
    }
    &.icon-attendance {
        background-image: url('/assets/icons/admin-attendance.svg');
    }
    &.icon-fees {
        background-image: url('/assets/icons/admin-fees.svg');
    }
    &.icon-settings {
        background-image: url('/assets/icons/admin-settings.svg');
    }
}

.bulk-upload {
    background: rgba(0, 0, 0, 0.01);
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 4px;

    &__heading {
        font-size: 25px;
        line-height: inherit;
        margin-bottom: 25px;
        font-weight: 700;
    }

    &__desc {
        margin: 12px 0;
        font-size: 16px;
        line-height: 26px;
        color: #868686;
    }

    strong {
        font-weight: bolder;
        color: black;
        padding-right: 0.4rem;
    }

    &__link {
        color: #5e5ad3 !important;
        padding-left: 0.3rem;
        font-weight: 600;
    }

    &__lists {
    }
}

.action {
    cursor: pointer;
    display: table-cell;
    padding-left: 4px;
    float: right;
    margin-left: auto;
}

.bootstrap {
    .search {
        border: none;
        background: transparent;
    }
    .tag {
        // background-color: $primary;
        // border: 1px solid $primary;
        // padding: 4px 0 4px 8px;
        // border-radius: 4px;
        // color: #fff;
        border-radius: 4px;
        color: #fff;
        background-color: rgb(230, 230, 230);
        display: flex;
        min-width: 0px;
        box-sizing: border-box;
        border-radius: 2px;
        margin: 2px;
        color: rgb(51, 51, 51);
        font-size: 85%;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        border-radius: 2px;
        overflow: hidden;
        padding: 3px 3px 3px 6px;

        .tag-remove {
            // color: #fff;
            // padding: 4px 8px;
            -webkit-box-align: center;
            align-items: center;
            display: flex;
            padding-left: 4px;
            padding-right: 4px;
            box-sizing: border-box;
            border-radius: 2px;
            margin-left: 4px;

            &:hover {
                background-color: rgb(255, 189, 173);
                color: rgb(222, 53, 11);
            }
        }
    }
    .dropdown-trigger {
        background: $white;
        border-radius: 0.25rem;
        &.arrow:after {
            content: '' !important;
        }
        &:hover {
            text-decoration: none;
        }
    }
    .dropdown-trigger > span:after {
        font-size: 12px;
        color: #555;
    }
    .toggle {
        font: normal normal normal 12px/1 FontAwesome;
        color: #555;
        margin-left: 0.5rem;
    }
    .node {
        min-width: 250px;
    }
    .node > label {
        display: inline-block;
        min-width: 100%;
        padding: 4px 8px;
        &:hover {
            background: $primary;
            color: $white;
        }
    }
    .node.leaf {
        padding-left: 14px;
    }
    .toggle.collapsed::after {
        content: '\f067';
    }
    .toggle.expanded::after {
        content: '\f068';
    }
}
