@import 'styles/variables.scss';

.test {
  // text-transform: capitalize;

  .test-name {
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .test-code {
    font-size: 1rem;
    line-height: 2rem;
    text-transform: capitalize;
    // color: #363;
    font-weight: 600;
  }

  .details {
    font-size: 0.8rem;
    text-transform: capitalize;
    // -webkit-flex-direction: row;
    // flex-direction: row;
    // display: flex;

    span {
      // display: flex;
      // flex-direction: column;
      // text-transform: uppercase;
      // color:#aaa;
      // font-size: .8rem;

      span + span {
        // font-weight: bold;
        // text-transform: none;
        // font-size: 1.2rem;
        padding-right: 1rem;
      }
    }

    // .duration {
    //     color: #5e5ad3;
    // }

    // .correct {
    //     color: green;
    // }

    // .wrong {
    //     color: red;
    // }

    // .unanswered {
    //     color: #363636;
    // }
  }
  .dynamic {
    p:last-child {
      margin-bottom: 0;
    }
  }

  .section {
    .section-details {
      border: 1px solid #dadce0;
      border-radius: 0.25rem;
      padding-bottom: 0;
      margin-bottom: 2rem;

      .wrapper {
        padding: 2rem;
      }
    }

    .table {
      margin-bottom: 0;
      th small {
        font-weight: bold;
      }
      td {
        text-transform: uppercase;
        color: #aaa;
        font-size: 0.8rem;
      }
    }

    .borderless td,
    .borderless th {
      border: none;
    }
  }

  .section-name {
    color: #3f51b5;
  }

  .badge {
    margin: 6px 3px;
    color: #ffffff;
  }

  .nav-tabs {
    padding: 0;
    margin: 1.5rem 0 1rem 0;
    // background: #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 3px;

    .nav-link {
      background-color: transparent;
      color: #363636 !important;
      border: none;
      border-bottom: 3px solid #f2f2f2;
      font-weight: 400;
      text-transform: initial;
      transition: 0.5s ease all;

      &.active {
        color: #3f51b5 !important;
        border-bottom: 3px solid #3f51b5;
        transition: 0.5s ease all;
      }
    }
  }

  .question {
    font-size: 16px;
    line-height: 24px;
    background: $white;
    // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .05);

    border: 1px solid #dadce0;
    position: relative;
    padding: 1.75rem 2.25rem;
    margin-bottom: 2.5rem;
    border-radius: 0.25rem;

    .qno {
      font-size: 1.3rem;
      font-weight: bold;
      margin-right: 0.5rem;

      &:after {
        content: '.';
      }
    }

    .qno + .content {
      // display: inline-flex;
    }

    .content {
      margin-bottom: 1rem;
    }

    .test-options {
      // list-style: decimal;
      .custom-control {
        position: relative;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
        border: 1px solid #ccc;
        margin: 0.5rem 0;
        padding: 0.9rem 2rem 0rem;
        border-radius: 3px;
      }
    }

    .test-sentence {
      text-transform: initial;
      font-size: 15px;
      line-height: 25px;

      p {
        color: black;

        img {
          width: 123px;
          height: 123px;
        }
      }
    }

    ul {
      li {
        p {
          margin-bottom: 0.5rem;
        }
      }
    }

    // pre {
    //     padding: 1rem 1rem 1rem 2rem
    // }

    // pre {
    //     padding: 2rem;
    //     background: #121212;
    //     color: #fff;
    //     box-shadow: 4px 4px 0 #757575;
    // }

    .explanation {
      background: #fafbfc;
      padding: 1rem;
      margin: 1rem 0;
    }

    .form-control {
      margin: 1rem 0;
    }

    .tags {
      .tag {
        font-size: 0.875rem;
        color: #4d4d4d;
        margin: 0 0.5rem 0.5rem 0;
        display: inline-block;
        padding: 4px 12px;
        border-radius: 1000px;
        background: #eee;
        font-weight: 500;
      }
    }

    .level {
      font-size: 0.875rem;
      color: #4d4d4d;
      margin: 0 0.5rem 0.5rem 0;
      display: inline-block;
      padding: 2px 18px;
      border-radius: 4px;
      background: #eee;
      font-weight: 500;
      color: #fff;

      &.easy {
        background: #8bc34a;
      }

      &.medium {
        background: #ffc107;
      }

      &.hard {
        background: #f44336;
      }
    }
  }

  .passage {
    // background: #ccc;
    background-color: $white;
    // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .05);

    border: 1px solid #dadce0;
    position: relative;
    padding: 1.75rem 0;
    margin-bottom: 2.5rem;
    border-radius: 0.25rem;

    &.directions {
      padding: 2.25rem 0 0 0;

      .test-passage {
        padding: 0 2.25rem 1rem;
      }
    }

    .question {
      font-size: 16px;
      line-height: 24px;
      background-color: $white;
      border: none;
      border-top: 1px solid #dadce0;
      margin-bottom: 0;
    }
  }
}

.no-border {
  .custom-control {
    border-width: 0 !important;
    margin: 0 !important;
    padding: 0 0 1rem 1.5rem !important;
    border-radius: 0 !important;

    p {
      margin: 0 !important;
    }
  }
}

.testPreview {
  .custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before,
  .custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }

  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: $white;
  }

  .alert {
    margin-bottom: 2.5rem;
  }

  .details-right {
    display: flex;
    flex-direction: column;
    justify-content: right;
    text-align: right;
  }

  .feedbacks {
    .hn-btn {
      padding: 0.25rem 0.75rem;
      border: 1px solid #e0e0e0;
      background: 0 0;
      border-radius: 1000px;
      cursor: pointer;
      line-height: 1;
      margin-right: 0.5rem;
      outline: 0;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      display: inline-block;
    }

    span {
      font-size: 1rem;
      font-weight: 600;
      color: #4d4d4d;
      display: inline-block;
      margin-left: 4px;
    }
  }
}

.MathJax_Preview {
  display: flex;
  font-family: monospace;
  white-space: pre;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.ques-params {
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: column;
  flex-direction: row;
  display: flex;

  div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;

    & > span {
      color: #aaa;
      font-size: 0.8rem;
    }

    span + span {
      font-weight: bold;
      color: black;
    }
  }
  .badge {
    font-size: 100%;
    color: black;
  }

  div:last-child {
    margin: 0 4rem 0 0;
  }
}
