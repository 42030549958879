@import 'styles/variables.scss';

.superfences-tabs {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

// .superfences-tabs .highlight {
//     background: #ddd;
// }

.superfences-tabs .superfences-content {
  display: none;
  order: 99;
  width: 100%;
  background: #23241f;
  color: #fff;
  padding: 2rem;
  margin-bottom: 1rem;

  pre {
    color: #fff;
  }
}

.superfences-tabs label {
  width: auto;
  margin: 0 0.5em 0 0;
  cursor: pointer;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem 1rem;
}

.superfences-tabs input {
  position: absolute;
  opacity: 0;
}

.superfences-tabs input:nth-child(n + 1) {
  color: #333333;
}

.superfences-tabs input:nth-child(n + 1):checked + label {
  color: #ffffff;
  font-weight: bold;
  background: #23241f;
}

.superfences-tabs
  input:nth-child(n + 1):checked
  + label
  + .superfences-content {
  display: block;
}

details {
  display: block;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  position: relative;
  margin: 1.5625em 0;
  padding: 0 1.2rem;
  border-left: 0.4rem solid #448aff;
  border-radius: 0.2rem;
  overflow: auto;

  p {
    margin-top: 1rem;
    margin-bottom: 1rem !important;
  }

  /* Attach the "no-details" class to details tags
    in browsers that do not support them to get
    open/show functionality. */
  &.no-details:not([open]) > * {
    display: none;
  }
  &.no-details:not([open]) summary {
    display: block;
  }

  &[open] > summary::before {
    content: '\f0aa';
  }

  &[open] > summary::after {
    content: '\f077';
  }

  summary {
    display: block;
    cursor: pointer;
    margin: 0 -1.2rem;
    padding: 0.8rem 1.2rem 0.8rem 3rem;
    border-bottom: 0.1rem solid rgba(68, 138, 255, 0.1);
    background-color: rgba(68, 138, 255, 0.1);
    font-weight: 700;

    &:focus {
      outline: none;
    }

    &::-webkit-details-marker {
      display: none;
    }

    &::before,
    &::after {
      position: absolute;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }

    &::before {
      padding-right: 0.5em;
      left: 1.2rem;
      color: #448aff;
      font-size: 1.5rem;
      // content: "\f0ab";
      content: '\f05a';
    }

    &::after {
      top: 0.8rem;
      right: 1.2rem;
      color: rgba(0, 0, 0, 0.26);
      font-size: 1rem;
      content: '\f078';
    }
  }

  &.danger {
    border-left-color: $danger;

    & > summary {
      border-bottom-color: 0.1rem solid rgba(255, 23, 68, 0.1);
      background-color: rgba(255, 23, 68, 0.1);

      &::before {
        color: $danger;
        content: '\f071';
      }
    }
  }

  &.success {
    border-left-color: $success;

    & > summary {
      border-bottom-color: 0.1rem solid rgba(0, 200, 83, 0.1);
      background-color: rgba(0, 200, 83, 0.1);

      &::before {
        color: $success;
        content: '\f058';
      }
    }
  }

  &.warning {
    border-left-color: $warning;

    & > summary {
      border-bottom-color: 0.1rem solid rgba(255, 145, 0, 0.1);
      background-color: rgba(255, 145, 0, 0.1);

      &::before {
        color: $warning;
        content: '\f071';
      }
    }
  }

  &.settings {
    border-left-color: $secondary;

    & > summary {
      border-bottom: 0.1rem solid rgba(170, 0, 255, 0.1);
      background-color: rgba(170, 0, 255, 0.1);

      &::before {
        color: $secondary;
        content: '\f085';
      }
    }
  }

  &:not([open]) > summary {
    border-bottom: none;
  }
}

[data-linenos]:before {
  content: attr(data-linenos);
}
