.demo-app {
    background-color: white;

    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none;
        transition: 0.5s all ease;
    }
}

.demo-app-top {
    margin: 0.1rem 0;

}

.demo-app-calendar {
    .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0.5em;
    }

    .fc-left {
        display: flex;
        align-items: center;
    }

    .btn-primary {
        color: #17a2b8;
        border-color: #17a2b8;
        background: white;
        padding: 0.1rem 1rem;
        font-size: 0.8rem;
        line-height: 1.8rem;

        &.fc-today-button {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: .25px;
            text-transform: none;
            opacity: 1;
            color: #3c4043;
            margin: 7px 12px 5px 0;
            border: 1px solid #3c4043;
            padding: 3px 12px;
        }

        &.fc-prev-button,
        &.fc-next-button {
            color: #5f6368;
            fill: #5f6368;
            opacity: 1;
            display: inline-block;
            position: relative;
            border: none;
            box-shadow: none;
            // span {
            //     &:hover {
            //         text-decoration: none;
            //         background-color: #f1f3f4;
            //         border: 1px solid #f1f3f4;
            //         border-radius: 50% !important;
            //     }
            // }
        }
    }

    .card-header {
        background-color: white;
    }

    .fc-popover .fc-header {
        padding: 15px 18px;
    }

    .table-bordered th,
    .table-bordered td {
        border-bottom: none;
        border-top: none;
    }
}

.demo-app-calendar,
.demo-app-top {
    background-color: white;
}

.fc-dayGridMonth-view {

    .fc-day-header {
        span {
            color: #70757a;
            font-size: 11px;
            font-weight: 400;
            line-height: 20px;
            text-transform: uppercase;
        }
    }

    .fc-day-top {
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }

    .fc-day-number {
        text-align: center;
        font-size: 10px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: .3px;
        float: none !important;
        display: inline-block;
        color: #70757a;
        margin: 0.2rem 0;
    }

    .fc-event {
        position: relative;
        display: block;
        font-size: 0.8rem;
        line-height: 1.8rem;
        border-radius: 3px;
        border: 1px solid #3788d8;
        text-align: left;
    }

    .fc-day {
        border: none;
        border-right: 1px solid #dadce0;
    }

    .fc-day-header {
        border-bottom: none;
        border-top: 1px solid #dee2e6;
        border-right: 1px solid #dee2e6;
        border-left: 1px solid #dee2e6;
    }


    .fc td.fc-today {
        border-style: none;
    }


    .fc-today {
        background: transparent !important;
        border-color: transparent;
    }

    .fc td.fc-today {
        border-style: none;
    }

    .fc-today {
        .fc-day-number {
            text-decoration: none;
            border-radius: 50%;
            padding: 1px 0.4rem;
            color: #fff;
            background-color: #1a73e8;
            border: 1px solid #1a73e8;
        }
    }
}


.spantag {
    font-size: 0.8rem;
}

.fc-more {
    padding: 2px;
    line-height: 16px;
    background-color: transparent;
    color: #3c4043;
    font-weight: 700;
    width: 93%;
    display: inline-block;
    margin: 1rem 0;
    border-radius: 4px;
    font-size: 0.7rem;
    cursor: pointer;

    &:hover {
        background-color: #dee2e6;
    }
}

.fc-more-popover {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149);
    background-color: #fff;
    border: none;

    .fc-day-grid-event {
        margin: 3px 0;
        padding: 0 1px;
        border-radius: 6px !important;
        font-size: 10px;
        line-height: 20px;
    }
}

.fc-content {
    padding: 0 1px;
    border-radius: 6px !important;
    font-size: 10px;
    line-height: 20px;
}

.fc.fc-bootstrap {
    .fc-timeGridWeek-view {
        a[data-goto]:hover {
            text-decoration: none;
            // height: 28px;
            // width: 28px;
            background-color: #f1f3f4;
            border: 1px solid #f1f3f4;
            border-radius: 0 !important;
            padding: 1px 0.4rem;
        }
    }
}

td.fc-axis.fc-time span {
    color: #70757a;
    font-size: 10px;
    -webkit-font-smoothing: subpixel-antialiased;
}

.fc-timeGridWeek-view {
    .fc-day-header {
        line-height: 32px;
        position: relative;
        z-index: 2;
        color: #70757a;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: .8px;
        margin-left: 0;
        margin-top: 8px;
        text-indent: .8px;
        text-transform: uppercase;
    }

    .fc-title {
        color: #70757a;
        font-size: 11px;
        letter-spacing: .8px;
        line-height: 16px;
        padding-bottom: 0;
        text-transform: uppercase;
    }
}

.fc-time-grid .fc-slats .fc-minor td {
    border-top: 1px solid #ccc;
}

hr.fc-divider {
    padding: 0;
    border: none;
}

.fc-event-dot {
    background-color: #999;
}

.fc-list-heading-main,
.fc-list-heading-alt {
    font-size: 11px;
    text-transform: uppercase;
    margin-left: 0;
    margin-top: 7px;
}

.fc-list-heading {
    border-bottom-width: 1px;
    background: rgba(0, 0, 0, 0.01);
}