//Color Set
$primary: #5e5ad3 !default;
$secondary: #17b7df !default;
$success: #28a745 !default;
$info: #17a2b8 !default;
$warning: #ffc107 !default;
$danger: #dc3545 !default;
$light: #f8f9fa !default;
$dark: #343a40 !default;

$body-bg: #f5f6fa;
$box-shadow: 0 4px 18px 0 rgba(6, 34, 44, 0.04) !default;
$nav-li-a: #555555 !default;
$black: #000000;
$white: #ffffff;

$gs: #10b857 !default;
$ge: #17b7df !default;

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
